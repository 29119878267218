import React from "react";

import image1 from './foto/20.gif';
import image2 from './foto/2.gif';
import image3 from './foto/4.gif';
import image5 from './foto/5.gif';
import image6 from './foto/8.gif';
import image7 from './foto/12.gif';
import image8 from './foto/26.gif';
import image9 from './foto/19.gif';
import image10 from './foto/23.gif';
import image11 from './foto/17.gif';

import './App.css';


const data=[
{
  imgUrl:image1
},
{
  imgUrl:image2
},{
    imgUrl:image3
}
,{
  imgUrl:image5
}

,{
  imgUrl:image6
}
,{
  imgUrl:image8
}

,{
  imgUrl:image7
}
,{
  imgUrl:image9
}
,{
  imgUrl:image10
},{
  imgUrl:image11
}
];






 class ImageComponent extends React.Component{
  render() {
 


    return (
      <div className="galeridiv">
      {    data.map((images) => 
   <img className="galerimg" key={images.imgUrl}  src={images.imgUrl} alt='satisfied patients' />

      )}
      </div>
    );
  }
 } 
export default ImageComponent;
