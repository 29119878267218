
import '../App.css';
import MetaTags from 'react-meta-tags';
import { FaInstagramSquare,FaViber,FaWhatsappSquare } from 'react-icons/fa';
import {BsPhone } from 'react-icons/bs';
import { GiLoveInjection} from 'react-icons/gi'
import { Link } from 'react-router-dom';
import Galerija from '../Galerija';


const En = () => (
  <div className='App' >

<MetaTags>
    <title> dr spec. Ana Lončar</title>
    <meta id="meta-description" name="description" content="dr spec. Ana Lončar - aesthetic treatments - aesthetic medicine - care and beauty" />
    <meta id="og-title" property="og:title" content="  aesthetic treatments - aesthetic medicine - care and beauty" />
    <meta id="og-image" property="og:image" content="https://dranaloncar.com/dranaloncar.jpg" />
    <meta property="og:image:type" content="image/jpg" />
    <meta property="og:image:secure_url" ccontent="https://dranaloncar.com/dranaloncar.jpg" />
    <meta property="og:description" content="dr spec. Ana Lončar - aesthetic treatments - aesthetic medicine - care and beauty" />
            <meta property="og:type" content="webiste" />
            <meta property="og:url" content="https://dranaloncar.com/en" />
            <meta property="og:site_name" content="dr spec. Ana Lončar" />

  </MetaTags>



<div style={{padding:''}}></div>
  <div style={{backgroundColor:' ',height:'',display:''}}>
    <div className='navbar' style={{borderBottom: ''}}>
<a className='icon' href='https://www.instagram.com/dr_ana_loncar_/' >
<FaInstagramSquare/> dr_ana_loncar_
</a>
<div className='buttons'>
  <Link className='linkbuttonstyle' to='/treatments'>
  <GiLoveInjection className='injectionicon'/>Treatments
</Link>
  <Link className='buttonstyle' to='/'>
SR
</Link>
<Link  className='buttonstyle'   style={{marginRight:'0'}} to='/en'  >EN</Link>
</div> 
    </div> 
    <div className='imagemobilediv' > <img   className='imagemobile' alt='dr spec. Ana Loncar'   data-aos="zoom-in-up"      
     data-aos-offset="200"
    data-aos-delay="50"
    data-aos-duration="1500"
    data-aos-easing="ease-in-out" 
   
    src={require('../slike/mobileimageen.png')}/>
  </div>
<div className='section1 '   > 
<div className='sectioncontainer'
 style={{backgroundColor:'  ', justifyContent:'center',alignItems:'center'}} > 
 <img className='sectionimage'   alt='dr spec. Ana Loncar' 
  data-aos="zoom-in"      
    data-aos-offset="200"
    data-aos-delay="50"
    data-aos-duration="1500"
    data-aos-easing="ease-in-out" 
    src={require('../slike/desktopimageen.png')}/>

  </div>

  <div className='textcontainer'  style={{backgroundColor:''}} > 
  <div className='divcontainer'  style={{backgroundColor:'', borderTop: ''}} >
   
    <p style={{backgroundColor:'',padding:'1%  ',textAlign:'center',color:'#B08047'}}  className='nameen'>
    - Ana Lončar, doctor of aesthetic medicine with 15 years of experience, educator in
     in the field of aesthetic medicine, specialist in medical microbiology -
      </p>
      <hr style={{border:'solid white 1px',padding:'  ',textAlign:'center',color:''}}    />
<p className=''   ><b style={{color:'#b08047', fontWeight:'bold', paddingRight:'.5%'}}>*</b>
In 2008, I graduated from the Faculty of Medicine in Belgrade, where I felt a special attraction towards aesthetic medicine.
    </p>
   <p><b style={{color:'#b08047', fontWeight:'bold', paddingRight:'.5%'}}>*</b>
   In 2009, I became part of the professional team at the Aesthetic and Educational Center "Medica Aesthetica". During six years,
    I had the privilege of organizing trainings in this center,
     and I still actively share my knowledge with other fellow doctors and dentists, at Basic and Advance trainings, teaching them various techniques of aesthetic medicine.
   </p>
 
   <p><b style={{color:'#b08047', fontWeight:'bold', paddingRight:'.5%'}}>*</b>
   In 2019, I became a medical microbiology specialist and gained invaluable experience during the pandemic.
    I worked on the establishment of the Microbiological-Molecular Laboratory and performed over 500,000 PCR tests.
   </p>
   <p>
   I have always had a passion for research and sharing new information and innovations from
in the field of medicine, and I channeled that passion through numerous lectures and activities.
Exchange of knowledge and experience through formal education, but also informal exchange, are
experiences that enrich me and through which I progress the most.
   </p>
   <div className='imagemobilediv'>

   <img className='imagemobile'   alt='dr spec. Ana Loncar' 
  data-aos="zoom-in"      
  data-aos-offset="150"
  data-aos-delay="50"
  data-aos-duration="1500"
  data-aos-easing="ease-in-out" 
  src={require('../slike/mobileimageana.png')}/>

  </div>
   <p className=''>
   My family provides me with inspiration, and the roles of wife and mother help me to understand the different needs of my patients.</p>
<p>
As a doctor of aesthetic medicine, my approach is special, for me each patient is a unique image whose value derives from authenticity.
My goal is not to create "perfect" faces, but to preserve and explore already existing authentic beauty, through carefully planned procedures
  which are adapted to the individual needs of the patient. Instead of using "one size fits all",
   I plan the procedures specifically for the patient, ensuring a natural look that emphasizes beauty.
My goal is to notice a positive change and an increase in self-confidence, but not to notice the means, ie. aesthetic procedure.
</p>
<p>
The greatest satisfaction as a doctor of aesthetic medicine comes from helping patients to
feel more confident and beautiful in their own skin.
</p>
<p className='thanknote'>

  <b className='thanknotebold'   >
  Thank you for your trust and support.
    </b> 
     <br/>

     In the service of your health and beauty,<b className='breaker'/> dr spec. Ana Lončar
</p>

  </div>
</div>
</div>

<div className='section1'   > 
<div className='sectioncontainer' style={{justifyContent:'center',alignItems:'center',backgroundColor:''}}  >
  </div>
<div className='textcontainer'    style={{backgroundColor:''}}   > 
<div className='divcontainer'  style={{backgroundColor:'',borderBottom:'solid white 2px'}} >
</div>
</div>
</div>

<div className='contact-section'  >

  <div style={{fontSize:'',color:'#B08047',display:'flex', margin:'0 10px', justifyContent:'center', textAlign:"center"}}> 
  <BsPhone/> < FaViber/> <FaWhatsappSquare/>
    +381 60 361 24 25
    
  </div>
  <div style={{fontSize:'',color:'#B08047',display:'flex', margin:'0 10px'}}> 
  <a className='icon' href='https://www.instagram.com/dr_ana_loncar_/' >
<FaInstagramSquare/> dr_ana_loncar_

</a>
  </div>


</div><div className='section4' style={{padding:''}}>
<div className='section-galery'>
<Galerija/>
</div>
 <div className='textbox'>

 <p><Link  to='/treatments' style={{color:'#F3F0EC'}}>Take a look at the treatment offer</Link> and let Dr. Ana Lončar guide you on the path to complete health, inner harmony and outer brilliance.
</p><p > Let her experience and passion become your partner in achieving your goals and realizing your dreams.</p>
 </div>
 <div style={{display:'flex',justifyContent:'center',color:'#F3F0EC', paddingTop:'1%'}}> 
 <a    href='https://marijana-portfolio-website.web.app/' target='_blank' rel="noreferrer"  style={{display:'flex',justifyContent:'center',color:'#F3F0EC',textDecoration:'none'}}   >  made by
      <img alt='Sample' style={{filter:'sepia(100%)'}}    src={require('../dizajnerlogo.png')}/>
       </a>  </div>
</div>

  </div>
  </div>
);

export default En;
