import '../App.css';
import { GiLoveInjection} from 'react-icons/gi'
import { FaInstagramSquare} from 'react-icons/fa';
import ImageComponent from '../Galery';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';


const Treatments = () => (
  <div className='App'>
<MetaTags>
    <title> Treatment offer</title>
    <meta id="meta-description" name="description" content="aesthetic treatments - fillers - botox - skin boosters - PRP - chemical peeling - lipolysis - mesonites" />
    <meta id="og-title" property="og:title" content="aesthetic treatments offer - fillers - botox - PRP" />
    <meta id="og-image" property="og:image" content="https://dranaloncar.com/dranaloncar.jpg" />
    <meta property="og:image:type" content="image/jpg" />
    <meta property="og:image:secure_url" ccontent="https://dranaloncar.com/dranaloncar.jpg" />
    <meta property="og:description" content="aesthetic treatments - fillers - botox - skin boosters - PRP - chemical peeling - lipolysis - mesonites" />
            <meta property="og:type" content="webiste" />
            <meta property="og:url" content="https://dranaloncar.com/treatments" />
            <meta property="og:site_name" content="dr spec. Ana Lončar - treatment offer" />

  </MetaTags>

<div className='navbar'   style={{borderBottom: '',paddingBottom:'.5%'}} >
<a className='icon' href='https://www.instagram.com/dr_ana_loncar_/'  >
<FaInstagramSquare/> dr_ana_loncar_

</a>
<div className='buttons'>
  <Link className='linkbuttonstyle' to='/treatments'>
  <GiLoveInjection className='injectionicon' />Treatments
</Link>
  <Link className='buttonstyle' to='/'>
SR
</Link>
<Link  className='buttonstyle' style={{marginRight:'0'}}  to='/en'  >EN</Link>
</div> 
    </div> 
<div   className='treatmensblock'>

<div className='section4' style={{backgroundColor:'#a4b689'}}>
<div className='section-galery' style={{backgroundColor:''}}>
<h3 style={{textAlign:'center',textDecoration:'underline',fontFamily:'Poiret One', padding:'0',marginBottom:'0'}}>Satisfied patients </h3>
<ImageComponent/>
</div>
        </div>
        <div className='section4'>

<div  style={{width:'',justifyContent:'center',display:'grid',backgroundColor:'#a4b689',padding:'2% 5% '}}>

<img className='tretmaniimgweb'  style={{width:'', maxWidth:'100%', border:'',padding:'0%', boxShadow:'1px 5px 8px rgb(128, 128, 128,.6)' }}
 alt='dr spec. Ana Loncar' 
src={require('../offers/treatmentoffer.png')}/>
<img className='tretmaniimgmob'  alt='dr spec. Ana Loncar'    style={{width:'', maxWidth:'100%', border:'',padding:'0%', boxShadow:'1px 5px 8px rgb(128, 128, 128,.6)' }}
src={require('../offers/mobileoffer.png')}/>
</div>
</div>
</div>

<div style={{display:'flex',justifyContent:'center',color:'#B08047', backgroundColor:'#a4b689',paddingTop:'0.5%'}}> 
 <a    href='https://marijana-portfolio-website.web.app/' target='_blank' rel="noreferrer"  style={{display:'flex',justifyContent:'center',color:'#F3F0EC',textDecoration:'none'}}   >  made by
      <img alt='Sample'  src={require('../dizajnerlogo.png')}/>
       </a> 
</div>

  </div>

);

export default Treatments;
