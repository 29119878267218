import React from "react";

import image1 from './foto/20.gif';
import image2 from './foto/2.gif';
import image3 from './foto/4.gif';
import image5 from './foto/5.gif';
import image8 from './foto/26.gif';
import image9 from './foto/18.gif';


import './App.css';


const data=[
{
  imgUrl:image1
},
{
  imgUrl:image2
},{
    imgUrl:image3
}
,{
  imgUrl:image5
}

,{
  imgUrl:image9
}
,{
  imgUrl:image8
}

];






 class Galerija extends React.Component{
  render() {
 


    return (
      <div className="galeri-div">
      {    data.map((images) => 
   <img className="galer-img"    key={images.imgUrl}  src={images.imgUrl} alt='zadovoljni pacijenti' />

      )}
      </div>
    );
  }
 } 
export default Galerija;
