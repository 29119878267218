
import '../App.css';
import MetaTags from 'react-meta-tags';
import { FaInstagramSquare,FaViber,FaWhatsappSquare } from 'react-icons/fa';
import {BsPhone } from 'react-icons/bs';
import { GiLoveInjection} from 'react-icons/gi'
import { Link } from 'react-router-dom';
import Galerija from '../Galerija';


const Home = () => (
  
  <div className='App' >

<MetaTags>
    <title> dr spec. Ana Lončar </title>
    <meta id="meta-description" name="description" content="dr spec. Ana Lončar - estetski tretmani - estetska medicina - nega i lepota" />
    <meta id="og-title" property="og:title" content="  estetski tretmani - estetska medicina - nega i lepota " />
    <meta id="og-image" property="og:image" content="https://dranaloncar.com/dranaloncar.jpg" />
    <meta property="og:image:type" content="image/jpg" />
    <meta property="og:image:secure_url" ccontent="https://dranaloncar.com/dranaloncar.jpg" />
    <meta property="og:description" content="dr spec. Ana Lončar - estetski tretmani - estetska medicina - zdravlje, nega i lepota" />
            <meta property="og:type" content="webiste" />
            <meta property="og:url" content="https://dranaloncar.com/" />
            <meta property="og:site_name" content="dr spec. Ana Lončar" />

  </MetaTags>

<div style={{padding:''}}></div>
  <div style={{backgroundColor:' ',height:'',display:''}}>
    <div className='navbar' style={{borderBottom: ''}}>
<a className='icon' href='https://www.instagram.com/dr_ana_loncar_/' >
<FaInstagramSquare/> dr_ana_loncar_
</a>
<div className='buttons'>
  <Link className='linkbuttonstyle' to='/tretmani'>
  <GiLoveInjection className='injectionicon'/>Tretmani
</Link>
  <Link className='buttonstyle' to='/'>
SR
</Link>
<Link  className='buttonstyle'   style={{marginRight:'0'}} to='/en'  >EN</Link>
</div> 
    </div> 
    <div className='imagemobilediv' > 
    <img   className='imagemobile'  alt='dr spec. Ana Loncar' 
      data-aos="zoom-in-up"      
     data-aos-offset="200"
    data-aos-delay="50"
    data-aos-duration="1500"
    data-aos-easing="ease-in-out" 
  
    src={require('../slike/mobileimagegold.png')}/>
  </div>
<div className='section1 '   > 
<div className='sectioncontainer'
 style={{backgroundColor:'  ', justifyContent:'center',alignItems:'center'}} > 
 <img className='sectionimage'   alt='dr spec. Ana Loncar' 
  data-aos="zoom-in"      
    data-aos-offset="200"
    data-aos-delay="50"
    data-aos-duration="1500"
    data-aos-easing="ease-in-out" 
    src={require('../slike/desktopimage.png')}/>

  </div>

  <div className='textcontainer'  style={{backgroundColor:''}} > 
  <div className='divcontainer'  style={{backgroundColor:'', borderTop: ''}} >
    <p style={{paddingTop:'1%  ',textAlign:'center',color:'#B08047'}}  className='namep'>
    - Ana Lončar doktor estetske medicine sa 15 godina iskustva, edukator u 
    oblasti estetske medicine, specijalista medicinske mikrobiologije -
      </p>
   <hr style={{border:'solid white 1px',padding:'  ',textAlign:'center',color:''}}    />

<p className=''   ><b style={{color:'#b08047', fontWeight:'bold', paddingRight:'.5%'}}>*</b>
2008. godine diplomirala sam na Medicinskom fakultetu u Beogradu, gde sam osetila posebnu privlačnost prema estetskoj medicini.
    </p>
   <p><b style={{color:'#b08047', fontWeight:'bold', paddingRight:'.5%'}}>*</b>
   2009. godine, postala sam deo stručnog tima u Estetskom i Edukativnom centru "Medica Aesthetica". Tokom šest godina, 
   imala sam privilegiju da organizujem obuke u ovom centru,
    a i dalje aktivno delim svoje znanje sa drugim kolegama lekarima i stomatolozima, na Basic i Advance edukacijama učeći ih raznim tehnikama estetske medicine.
   </p>
 
   <p><b style={{color:'#b08047', fontWeight:'bold', paddingRight:'.5%'}}>*</b>
   2019. godine postala sam specijalista medicinske mikrobiologije i sticala neprocenjivo iskustvo tokom pandemije. 
   Radila sam na osnivanju Mikrobiološko-molekularne laboratorije i obavila preko 500.000 PCR testova.
   </p>
   <p>
   Oduvek sam gajila strast prema istraživanju i deljenju novih informacija i inovacija iz
oblasti medicine, a tu strast sam kanalisala kroz brojna predavanja i aktivnosti.
Razmena znanja i iskustva kroz formalne edukacije, ali i neformalnu razmenu, jesu
iskustva koja me oplemenjuju i kroz koja najviše napredujem.
   </p>
   <div className='imagemobilediv'>

   <img className='imagemobile'   alt='dr spec. Ana Loncar' 
  data-aos="zoom-in"      
  data-aos-offset="150"
  data-aos-delay="50"
  data-aos-duration="1500"
  data-aos-easing="ease-in-out" 
  src={require('../slike/mobileimageana.png')}/>

  </div>
   <p className=''>
Porodica mi pruža inspiraciju, a uloge supruge i majke pomažu mi da razumem različite potrebe mojih pacijenta.</p>
<p>
Kao lekar estetske medicine, moj pristup je poseban, za mene svaki pacijent je jedinstvena slika čija vrednost proističe iz autentičnosti. 
Moj cilj nije da stvaram "savršena" lica, već da očuvam i istražim već postojeću autentičnu lepotu, kroz pažljivo planirane procedure
 koje se prilagođavaju individualnim potrebama pacijenta. Umesto da koristim "jedna veličina odgovara svima",
  procedure planiram specifično za pacijenta, osiguravajući prirodan izgled koji naglašava lepotu.
Moj cilj je da se primeti pozitivna promena i povećanje samopouzdanja, ali da se ne primeti sredstvo tj. estetska procedura.
</p>
<p>
 Najveće zadovoljstvo kao lekaru estetske medicine dolazi iz pomaganja pacijentima da
se osećaju samopouzdanije i lepše u svojoj koži.
</p>
<p className='thanknote'>

  <b className='thanknotebold'   >
     Hvala vam na poverenju i podršci.
    </b> 
     <br/>
          U službi vašeg zdravlja i lepote, <br className='breaker'/>  dr spec. Ana Lončar
</p>
  </div>
</div>
</div>

<div className='section1'   > 

<div className='sectioncontainer' style={{justifyContent:'center',alignItems:'center',backgroundColor:''}}  >
  </div>
<div className='textcontainer'    style={{backgroundColor:''}}   > 
<div className='divcontainer'  style={{backgroundColor:'',borderBottom:'solid white 2px'}} >
</div>
</div>
</div>

<div className='contact-section'  >
  <div style={{fontSize:'',color:'#B08047',display:'flex', margin:'0 10px', justifyContent:'center', textAlign:"center"}}> 
  <BsPhone/> < FaViber/> <FaWhatsappSquare/>
    +381 60 361 24 25
  </div>

  <div style={{fontSize:'',color:'#B08047',display:'flex', margin:'0 10px'}}> 
  <a className='icon' href='https://www.instagram.com/dr_ana_loncar_/' >
<FaInstagramSquare/> dr_ana_loncar_
</a>
  </div>
</div>

<div className='section4' style={{padding:''}}>
<div className='section-galery'>
<Galerija/>
</div>
 <div className='textbox'>

 <p><Link  to='/TRETMANI' style={{color:'#F3F0EC'}}>Pogledajte ponudu tretmana</Link> 
 i dopustite dr Ani Lončar da vas vodi putem ka potpunom zdravlju, unutrašnjoj harmoniji i spoljašnjoj sjajnosti.
</p>
<p > Neka njeno iskustvo i strast postanu vaš partner u ostvarivanju vaših ciljeva i ostvarenju vaših snova.</p>
 </div>

 <div style={{display:'flex',justifyContent:'center',color:'#F3F0EC', paddingTop:'1%'}}> 
 <a href='https://marijana-portfolio-website.web.app/' target='_blank' rel="noreferrer"  style={{display:'flex',justifyContent:'center',color:'#F3F0EC',textDecoration:'none'}}   >  made by
      <img alt='Sample' style={{filter:'sepia(100%)'}}    src={require('../dizajnerlogo.png')}/>
  </a> 
   </div>
</div>

  </div>
  </div>
);

export default Home;
