import React from 'react';
import {  BrowserRouter as Router,  Routes,  Route} from "react-router-dom";
import './App.css';
import Home from './pages/home';
import ScrollToTop from './ScrollToTop';
import MetaTags from 'react-meta-tags';
import Tretmani from './pages/tretmani';
import AOS from 'aos';
import 'aos/dist/aos.css';
import En from './pages/en';
import Treatments from './pages/treatments';

AOS.init({
  
})


function App() {
  return (
   
        <Router>
  <ScrollToTop>
    <div className="App">
    <MetaTags>
    <title> dr spec. Ana Lončar </title>
    <meta id="meta-description" name="description" content="dr spec. Ana Lončar - estetski tretmani - estetska medicina - nega i lepota" />
    <meta id="og-title" property="og:title" content="  estetski tretmani - estetska medicina - nega i lepota " />
    <meta id="og-image" property="og:image" content="https://dranaloncar.com/dranaloncar.jpg" />
    <meta property="og:image:type" content="image/jpg" />
    <meta property="og:image:secure_url" ccontent="https://dranaloncar.com/dranaloncar.jpg" />
    <meta property="og:description" content="dr spec. Ana Lončar - tretmani - estetska medicina - zdravlje, nega i lepota" />
            <meta property="og:type" content="webiste" />
            <meta property="og:url" content="https://dranaloncar.com/" />
            <meta property="og:site_name" content="dr spec. Ana Lončar" />

  </MetaTags>

              <div>
            <Routes>
              <Route path="/" exact element={<Home/>} />
              <Route path="/tretmani" element={<Tretmani/>} />
              <Route path="/treatments" element={<Treatments/>} />
              <Route path="/en" exact element={<En/>} />
            </Routes>
              </div>
    
    </div></ScrollToTop>
        </Router>

   
  );
}

export default App;